<template>
  <div class="d-flex flex-wrap mt-2 justify-space-between align-center">
    <span class="primary--text font-size--12 w-60px">
      {{ $t('timesheetUI.EntryFormLabels.TimesheetEntryLabelTask') }}</span
    >
    <VSelect
      :value="selectedTasks.altairTask || ''"
      :items="
        (altairTasksByJobId[job.job_ID] &&
          altairTasksByJobId[job.job_ID].data) ||
        []
      "
      :loading="
        altairTasksByJobId[job.job_ID] &&
        altairTasksByJobId[job.job_ID].isDataLoading
      "
      :placeholder="
        $t('timesheetUI.EntryFormLabels.TimesheetEntryPsTaskPlaceholder')
      "
      item-text="label"
      item-value="value"
      outlined
      attach
      dense
      :menu-props="{
        bottom: true,
        offsetY: true,
        nudgeBottom: 0,
      }"
      id="joblist_altair_select"
      ref="joblist_altair_select"
      hide-details
      append-icon="mdi-chevron-down"
      class="font-size--14"
      @input="setSelectedTask(job.job_ID, 'altairTask', $event)"
      @click="loadAltairTasks(job, altairTasksByJobId[job.job_ID])"
      @keydown.space.prevent="
        loadAltairTasks(job, altairTasksByJobId[job.job_ID])
      "
      @keydown.enter.prevent="
        loadAltairTasks(job, altairTasksByJobId[job.job_ID])
      "
      @keydown="arrowKeyHandler"
      :error="error"
    >
      <template v-slot:no-data>
        <div
          v-if="
            !altairTasksByJobId[job.job_ID] ||
            (altairTasksByJobId[job.job_ID] &&
              altairTasksByJobId[job.job_ID].isDataLoading)
          "
        >
          <VListItem
            ><VListItem>
              <VListItemContent>
                <VListItemTitle>Loading...</VListItemTitle>
              </VListItemContent>
            </VListItem></VListItem
          >
        </div>
        <div v-else>
          <VListItem>
            <VListItemContent>
              <VListItemTitle>
                {{ $t('General.Nodataavailable') }}</VListItemTitle
              >
            </VListItemContent>
          </VListItem>
        </div>
      </template>
    </VSelect>
  </div>
</template>

<script>
import {
  arrowKeyHandler as arrowKeyHandlerClick,
  handleArrowKeys,
} from '@Shared/filters'
import { debounce } from 'lodash'
import {
  mapState,
  mapGetters,
  mapActions,
  // mapMutations
} from 'vuex'

export default {
  name: 'AltairTaskDropdown',

  props: ['job', 'closeSearchJobDialog', 'showSearchJobDialog', 'error'],

  data() {
    return {
      selectedTasks: {},
    }
  },

  computed: {
    ...mapState('storeTimesheets', ['altairTasksByJobId']),

    ...mapGetters('storeTimesheets', ['timesheetJobs']),
  },

  methods: {
    ...mapActions('storeTimesheets', ['fetchAltairTasks']),

    loadAltairTasks(job, fetchedAltairTasks) {
      if (fetchedAltairTasks?.isDataLoaded) return

      this.fetchAltairTasks({ jobId: job.job_ID }).then(() => {
        this.$refs?.joblist_altair_select.$children[1].activate()
      })
    },

    setSelectedTask(jobId, taskType, selectedTask) {
      this.selectedTasks = { ...this.selectedTasks, [taskType]: selectedTask }
      this.$emit('change', { jobId, taskType, selectedTask })
    },

    resetJobCard() {
      this.selectedTasks = {}
    },
    arrowKeyHandler: debounce(function ($e) {
      arrowKeyHandlerClick($e)
      handleArrowKeys(this, $e, ['joblist_altair_select'])
    }, 300),
  },

  watch: {
    showSearchJobDialog: {
      immediate: true,
      handler(newVal) {
        if (!newVal) this.resetJobCard()
        if (newVal) {
          this.$nextTick(() => {
            document
              .querySelector('.timesheet-search-job--items')
              ?.addEventListener('scroll', () => {
                this.$refs?.joblist_altair_select?.$children[1].activate()
              })
          })
        } else {
          document
            .querySelector('.timesheet-search-job--items')
            ?.removeEventListener('scroll', () => {
              this.$refs?.joblist_altair_select?.$children[1].activate()
            })
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.w-60px {
  width: 9ch;
}
</style>
