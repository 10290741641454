<template>
  <div class="d-flex flex-wrap mt-2 justify-space-between align-center">
    <span class="primary--text font-size--12 w-60px">{{
      $t('timesheetUI.EntryFormLabels.TimesheetEntryPsTask')
    }}</span>
    <VSelect
      :value="selectedTasks.PPMTask || ''"
      :items="
        (PPMTasksByJobId[job.job_ID] && PPMTasksByJobId[job.job_ID].data) || []
      "
      :loading="
        PPMTasksByJobId[job.job_ID] && PPMTasksByJobId[job.job_ID].isDataLoading
      "
      :placeholder="
        $t('timesheetUI.EntryFormLabels.TimesheetEntryPsTaskPlaceholder')
      "
      item-text="label"
      item-value="value"
      outlined
      attach
      dense
      id="joblist_ppm_select"
      ref="joblist_ppm_select"
      :menu-props="{
        bottom: true,
        offsetY: true,
        nudgeBottom: 0,
      }"
      hide-details
      append-icon="mdi-chevron-down"
      class="font-size--14"
      @input="setSelectedTask(job.job_ID, 'PPMTask', $event)"
      @click="loadPPMTasks(job, PPMTasksByJobId[job.job_ID])"
      @keydown.space.prevent="loadPPMTasks(job, PPMTasksByJobId[job.job_ID])"
      @keydown.enter.prevent="loadPPMTasks(job, PPMTasksByJobId[job.job_ID])"
      @keydown="arrowKeyHandler"
      :error="error"
    >
      <template v-slot:no-data>
        <div
          v-if="
            !PPMTasksByJobId[job.job_ID] ||
            (PPMTasksByJobId[job.job_ID] &&
              PPMTasksByJobId[job.job_ID].isDataLoading)
          "
        >
          <VListItem
            ><VListItem>
              <VListItemContent>
                <VListItemTitle>Loading...</VListItemTitle>
              </VListItemContent>
            </VListItem></VListItem
          >
        </div>
        <div v-else>
          <VListItem>
            <VListItemContent>
              <VListItemTitle>{{
                $t('General.Nodataavailable')
              }}</VListItemTitle>
            </VListItemContent>
          </VListItem>
        </div>
      </template>
    </VSelect>
  </div>
</template>

<script>
import {
  arrowKeyHandler as arrowKeyHandlerClick,
  handleArrowKeys,
} from '@Shared/filters'
import { debounce } from 'lodash'
import {
  mapState,
  mapGetters,
  mapActions,
  // mapMutations
} from 'vuex'

export default {
  name: 'PPMTaskDropdown',

  props: ['job', 'closeSearchJobDialog', 'showSearchJobDialog', 'error'],

  data() {
    return {
      selectedTasks: {},
    }
  },

  computed: {
    ...mapState('storeTimesheets', ['PPMTasksByJobId']),

    ...mapGetters('storeTimesheets', ['timesheetJobs']),
  },

  methods: {
    ...mapActions('storeTimesheets', ['fetchPPMTasks']),

    loadPPMTasks(job, fetchedPPMTasks) {
      if (fetchedPPMTasks?.isDataLoaded) return

      this.fetchPPMTasks({ jobId: job.job_ID }).then(() => {
        this.$refs?.joblist_ppm_select.$children[1].activate()
      })
    },

    setSelectedTask(jobId, taskType, selectedTask) {
      const selectedTaskCopy = JSON.parse(JSON.stringify(this.selectedTasks))

      selectedTaskCopy.PPMTask = selectedTask

      this.selectedTasks = selectedTaskCopy

      this.$emit('change', { jobId, taskType, selectedTask })
    },

    resetJobCard() {
      this.selectedTasks = {}
    },
    arrowKeyHandler: debounce(function ($e) {
      arrowKeyHandlerClick($e)
      handleArrowKeys(this, $e, ['joblist_ppm_select'])
    }, 300),
  },

  watch: {
    showSearchJobDialog: {
      immediate: true,
      handler(newVal) {
        if (!newVal) this.resetJobCard()
        if (newVal) {
          this.$nextTick(() => {
            document
              .querySelector('.timesheet-search-job--items')
              ?.addEventListener('scroll', () => {
                this.$refs?.joblist_ppm_select?.$children[1].activate()
              })
          })
        } else {
          document
            .querySelector('.timesheet-search-job--items')
            ?.removeEventListener('scroll', () => {
              this.$refs?.joblist_ppm_select?.$children[1].activate()
            })
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.w-60px {
  width: 9ch;
}
</style>
