var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"time-entry-form"},[(!_vm.loadingFeatures && _vm.timesheetDetailFeatures)?_c('div',[(!_vm.validForEntry)?_c('div',[_c('v-alert',{staticClass:"v-alert--custom",attrs:{"dense":"","type":"error","icon":"mdi-alert-circle-outline"}},[(
            _vm.timesheetDetailFeatures &&
            _vm.timesheetDetailFeatures.activity.required
          )?_c('span',{staticClass:"font-color--body"},[_vm._v(_vm._s(_vm.$t('timesheetUI.SearchJobs.NoStudioActivities')))]):_vm._e(),(
            _vm.timesheetDetailFeatures &&
            _vm.timesheetDetailFeatures.workcode.showAbsenceErrorMessage
          )?_c('span',{staticClass:"font-color--body"},[_vm._v(_vm._s(_vm.$t('timesheetUI.SearchJobs.NotEligible')))]):_c('span',{staticClass:"font-color--body"},[_vm._v(_vm._s(_vm.$t('timesheetUI.SearchJobs.NoJobs')))])])],1):(_vm.isTimesheetBeingReviewed)?_c('timesheet-entry-detail-review'):_c('div',[_c('CopyToRestOfWeek'),(
          !_vm.timesheetDetailFeatures ||
          (!_vm.timesheetDetailFeatures.activity.required &&
            !_vm.timesheetDetailFeatures.task.required)
        )?_c('div',[(_vm.isLocationActiveForThisDay)?_c('timesheet-entry-detail-location'):_c('timesheet-entry-detail-default')],1):_c('div',[(_vm.timesheetDetailFeatures.activity.required)?_c('timesheet-entry-detail-studio'):(_vm.timesheetDetailFeatures.task.required)?_c('timesheet-entry-detail-task'):_c('timesheet-entry-detail-default')],1),(_vm.timesheetDetail && _vm.timesheetDetail.id)?_c('div',[_c('v-row',[(!_vm.isTemporaryExternalEmployee)?_c('v-col',[_c('div',{class:`font-size--14 font-weight--600 mb-1}`},[_vm._v(" "+_vm._s(_vm.$t( 'timesheetUI.EntryFormLabels.TimesheetEntryAdditionalInformation' ))+" ")]),(!_vm.isEnabledSelfCorrection)?_c('div',[_vm._v(" "+_vm._s(_vm.timesheetStatusDescriptions[ _vm.timesheetDetail.timesheetStatus.toLowerCase() ])+" ")]):_c('div',[_vm._v(_vm._s(_vm.getSelfCorrectionRemainingDays))]),(_vm.timesheetDetail.isWipCorrected)?_c('div',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('timesheetUI.EntryFormLabels.WIPCorrectedMessage'))+" ")]):_vm._e()]):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.isTimeEntryDrawerExpanded ? 6 : 12}},[_c('div',{staticClass:"mb-1 timesheet-details-label"},[_vm._v(" "+_vm._s(_vm.$t('timesheetUI.InfoPanel.LabelSubmittedBy'))+" ")]),(
                _vm.timesheetDetail.submittedByAltairCode &&
                _vm.timesheetDetail.timesheetStatus.toLowerCase() !==
                  'unsubmitted'
              )?_c('div',[_vm._v(" "+_vm._s(_vm.timesheetDetail.submittedByName)+" ("+_vm._s(_vm.timesheetDetail.submittedByAltairCode)+") ")]):_c('div',[_vm._v("-")])]),(!_vm.isTemporaryExternalEmployee)?_c('v-col',{attrs:{"cols":_vm.isTimeEntryDrawerExpanded ? 6 : 12}},[_c('div',{staticClass:"mb-1 timesheet-details-label"},[_vm._v(" "+_vm._s(_vm.$t('timesheetUI.InfoPanel.LabelApprover'))+" ")]),(_vm.timesheetDetail.assignedApproverName)?_c('div',[_vm._v(" "+_vm._s(_vm.timesheetDetail.assignedApproverName)+" ("+_vm._s(_vm.timesheetDetail.assignedApproverAltairCode)+") ")]):_c('div',[_vm._v("-")])]):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.isTimeEntryDrawerExpanded ? 6 : 12}},[_c('div',{staticClass:"mb-1 timesheet-details-label"},[_vm._v(" "+_vm._s(_vm.$t('timesheetUI.InfoPanel.LabelSubmittedOn'))+" ")]),(
                _vm.timesheetDetail.submittedDate &&
                _vm.timesheetDetail.timesheetStatus.toLowerCase() !==
                  'unsubmitted'
              )?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatDateMonthAsWord")(_vm.timesheetDetail.submittedDate))+" ")]):_c('div',[_vm._v("-")])]),(!_vm.isTemporaryExternalEmployee)?_c('v-col',{attrs:{"cols":_vm.isTimeEntryDrawerExpanded ? 6 : 12}},[(
                _vm.timesheetDetail.timesheetStatus.toLowerCase() === 'rejected'
              )?_c('div',[_c('div',{staticClass:"mb-1 timesheet-details-label"},[_vm._v(" "+_vm._s(_vm.$t('timesheetUI.InfoPanel.LabelRejectedOn'))+" ")]),(_vm.timesheetDetail.approvalDate)?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatDateMonthAsWord")(_vm.timesheetDetail.approvalDate))+" ")]):_c('div',[_vm._v("-")])]):_c('div',[_c('div',{staticClass:"mb-1 timesheet-details-label"},[_vm._v(" "+_vm._s(_vm.$t('timesheetUI.InfoPanel.LabelApprovedOn'))+" ")]),(
                  _vm.timesheetDetail.approvalDate &&
                  _vm.timesheetDetail.timesheetStatus.toLowerCase() !==
                    'unsubmitted'
                )?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatDateMonthAsWord")(_vm.timesheetDetail.approvalDate))+" ")]):_c('div',[_vm._v("-")])])]):_vm._e()],1),(_vm.timesheetDetail.rejectionComment)?_c('v-row',[(!_vm.isTemporaryExternalEmployee)?_c('v-col',[_c('div',{staticClass:"mb-1 font-size--13 font-weight--600"},[_vm._v(" "+_vm._s(_vm.$t('timesheetUI.InfoPanel.Comment'))+" ")]),_c('div',[_vm._v(_vm._s(_vm.timesheetDetail.rejectionComment))])]):_vm._e()],1):_vm._e()],1):_vm._e(),(
          _vm.timesheetDetailFeatures.location.required &&
          _vm.timesheetDetail.timesheetStatus !== 'Approved'
        )?_c('div',{staticClass:"px-4"},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[(_vm.agencyData.isPsAgency)?_c('div',{staticClass:"time-entry__info-message error--text"},[_vm._v(" "+_vm._s(_vm.$t('timesheetUI.InfoPanel.locationUpdate.header'))+" "),_c('ul',[_c('li',[_vm._v(" "+_vm._s(_vm.$t('timesheetUI.InfoPanel.locationUpdate.list1'))+" ")]),_c('li',[_vm._v(" "+_vm._s(_vm.$t('timesheetUI.InfoPanel.locationUpdate.list2'))+" ")])]),_c('br')]):_vm._e(),_c('div',{staticClass:"time-entry__info-message"},[_vm._v(" "+_vm._s(_vm.$t('timesheetUI.ValidationMessages.LocationMessage'))+" ")])])],1)],1):_vm._e()],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }